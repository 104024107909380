import React, { useEffect, useState } from "react"
import * as Component from '../components'
import PerfectScrollbar from "react-perfect-scrollbar"
import { Tabs, Tab, Container, Row, Col } from "react-bootstrap"

const Career = () => {
  const isBrowser = typeof window !== "undefined"
  //const [winWidth, setWin] = useState(null)
  const [key, setKey] = useState('overview');

  useEffect(() => {
    let isLoad = true
    if (isLoad && isBrowser) {
      //setWin(window.innerWidth)
      const t = window.localStorage.getItem('isCareer')
      setKey(t)
    }
    return () => {
      isLoad = false
    }
  }, [isBrowser])


  return (
    <Component.Layout className="page-career" isClick={e => setKey(e)}>
      {/* CHILDRENN */}
      <Container>
        <Row>
          <Col md={8} lg={6}>
            <div className="tab-wrapper">
            <Tabs
                defaultActiveKey={key}
                activeKey={key}
                onSelect={(k) => setKey(k)}
                id="Career"
              >
                <Tab eventKey="overview" title="Overview">
                  <h2>THE COMPANY</h2>
                  <PerfectScrollbar>
                    <p>
                      Emirates Face Recognition LLC ('Emirates FR') creates high
                      performance, state-of-the-art face recognition enabled
                      solutions for,
                      <br></br>
                    </p>
                    <ul>
                      <li>
                        <Component.Icon icon="circle" color="white" size="0.3em"></Component.Icon>
                        securing issuance of travel and identification documents
                      </li>
                      <li>
                        <Component.Icon icon="circle" color="white" size="0.3em"></Component.Icon>
                        facilitating border clearance
                      </li>
                      <li>
                        <Component.Icon icon="circle" color="white" size="0.3em"></Component.Icon>
                        enabling airport operators to deliver new revenue
                        generating passenger facilitation services
                      </li>
                      <li>
                        <Component.Icon icon="circle" color="white" size="0.3em"></Component.Icon>
                        protecting critical infrastructure and key assets vital
                        to the nation
                      </li>
                      <li>
                        <Component.Icon icon="circle" color="white" size="0.3em"></Component.Icon>
                        enhancing law enforcement's ability to identity people
                      </li>
                      <li>
                        <Component.Icon icon="circle" color="white" size="0.3em"></Component.Icon>
                        securing value exchange for machine-assisted service
                        delivery
                      </li>
                    </ul>
                    <p>
                      <br></br>
                      To realise best-of-breed solutions, Emirates FR develops
                      an extensive knowledge of the business processes the
                      solutions are designed to enhance, takes a comprehensive
                      approach to solution provision and uses state-of-the-art
                      face recognition enabling technology to achieve the
                      highest recognition performance when operating in
                      real-world settings. Emirates FR has cemented its position
                      as the number one provider by delivering solutions that
                      perform unobtrusively within the unique operational
                      requirements of the business process and deliver
                      recognition findings in seconds.
                    </p>
                  </PerfectScrollbar>
                </Tab>
                <Tab eventKey="career" title="Career">
                  <h2>CAREER</h2>
                  <PerfectScrollbar>
                    <p>
                      Do you have the skills and experience; the drive and
                      determination to be part of a world leading facial
                      biometric technology company?<br></br> <br></br> Emirates
                      FR is always looking for talented professionals who have a
                      highly collaborative approach and are driven to push the
                      innovation envelope while producing best-of-breed results.
                      If you’re a technology-loving self-starter and thrive in a
                      fast-growing international environment, please send us
                      your résumé at
                      <span>careers@facerecognition.ae.</span>
                    </p>
                  </PerfectScrollbar>
                </Tab>
                <Tab eventKey="legal" title="Legal Notice">
                  <h2>LEGAL NOTICE</h2>

                  <PerfectScrollbar>
                    <p>
                      <strong>General </strong>
                      The information appearing on this web site is for your
                      general guidance. It is not intended to provide you with
                      legal advice and is subject to change without notice.
                      Please consult your own legal advisor before taking any
                      action based on information appearing on this web site or
                      any site to which it may be linked. The design and layout
                      of this web site are both the copyrighted property of
                      Emirates FR, so please don't copy either.
                      <br></br> <br></br>
                      <strong>Disclaimer</strong>
                      THE INFORMATION, DOCUMENTS AND/OR SOFTWARE MADE AVAILABLE
                      ON THIS SITE ARE PROVIDED "AS IS" WITHOUT ANY EXPRESS OR
                      IMPLIED WARRANTIES OR CONDITIONS OF ANY KIND WHETHER
                      ARISING BY LAW, USAGE OR TRADE.
                      <br></br> <br></br>
                      <strong>Documents available on this site</strong>
                      You may use and copy the documents made available on this
                      web site subject to the following:
                      <br></br> <br></br>
                    </p>
                    <ul>
                      <li>
                        <Component.Icon icon="circle" color="white" size="0.3em"></Component.Icon>
                        Any copy of the document must contain the Copyright
                        Notice that accompanies the document
                      </li>
                      <li>
                        <Component.Icon icon="circle" color="white" size="0.3em"></Component.Icon>
                        You cannot alter the document
                      </li>
                      <li>
                        <Component.Icon icon="circle" color="white" size="0.3em"></Component.Icon>
                        You can only use the document for your personal
                        non-commercial use.
                      </li>
                    </ul>
                    <p>
                      <br></br> <br></br>
                      <strong>Links to third party sites</strong>
                      This web site may contain hyperlinks, which will take you
                      out of the Emirates FR site and connect you to third party
                      sites. Emirates FR is providing these hyperlinks to you
                      only as a convenience. The inclusion of any hyperlink does
                      not imply endorsement by Emirates FR of the site. These
                      sites are not under the control of Emirates FR and by
                      accessing them you do so at your own risk.
                      <br></br> <br></br>
                      <strong>Governing laws and jurisdiction</strong>
                      This web site is controlled by Emirates FR, Abu Dhabi,
                      UAE. By accessing this site, you agree that any legal
                      matter that may arise between you and Emirates FR relating
                      to the contents of this web site shall be governed by the
                      laws of Abu Dhabi (UAE) and exclusively by its courts.
                      <br></br> <br></br>
                      <span style={{ textDecoration: "none" }}>
                        COPYRIGHT NOTICE. ©2015 Emirates Face Recognition LLC.
                      </span>
                      <br></br> <br></br>
                      All rights reserved. We welcome your comments with respect
                      to the Emirates FR Terms of Use. Any comments or questions
                      should be sent by e-mail to webmaster@facerecognition.ae.
                      We will use commercially reasonable efforts to promptly
                      respond to your inquiry.
                    </p>
                  </PerfectScrollbar>
                </Tab>
              </Tabs>
            </div>
          </Col>
        </Row>
      </Container>
    </Component.Layout>
  )
}

export default Career